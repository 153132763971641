import React from 'react';
import ImageMeta from "../../components/ImageMeta";
import MarkdownViewer from "@components/MarkdownViewer"


const StaticHero = (props) => {
    const data = props.post.staticHero;

    const styleTwo = true;

    if (!styleTwo) {
        return (
            <>
            <div 
                className="homepage-hero" 
                style={{
                    backgroundImage: `url(https://res.cloudinary.com/nuvolum/image/upload/c_scale,f_auto,q_99,w_500/v1/${data.backgroundImage})`
                }}
            >
            <h1 id="SEOTitle">{props.post.seoHeading}</h1>
                <div className="columns">
                    {/* <div className="column is-1"></div> */}

                    <div className="column hero-side-image">
                        <ImageMeta
                            cloudName="nuvolum"
                            publicId={data.sideImage}
                            responsive
                        />
                    </div>

                    <div className="column is-1"></div>

                    <div className="column is-11 hero-text-desktop">
                        <ImageMeta
                            cloudName="nuvolum"
                            publicId={data.logo}
                            responsive
                        />
                        <MarkdownViewer markdown={data.heading} />
                    </div>

                    <div className="column is-1"></div>
                </div>
            </div>
                <div className="column hero-text-mobile">
                    <div>
                        <MarkdownViewer markdown={data.heading} />
                    </div>
                </div>
            </>
        )
    } else {
        return <StyleTwo data={data} seoH1={props.post.seoHeading} />
    }
}

const StyleTwo = ({ data, seoH1 }) => {
    return (
        <div 
            className="homepage-hero" 
            style={{
                backgroundImage: `url(https://res.cloudinary.com/nuvolum/image/upload/c_scale,f_auto,q_99,w_500/v1/${data.backgroundImage})`
            }}
        >
            <h1 id="SEOTitle">{seoH1}</h1>
            <div className="hero-2-container">
                <ImageMeta
                    cloudName="nuvolum"
                    publicId={data.logo}
                    responsive
                />

                <MarkdownViewer className="hero-heading" markdown={data.heading} />
            </div>
        </div>
    )
};

export default StaticHero
